.header-mother {
    display: flex;
    flex-direction: column;
    /* border: solid 3px green; */
    padding: 100px 90px;
}

.header-top {
    display: flex;
    /* border: solid 3px blue; */
    justify-content: center;
}

.header-top-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    /* border: solid 3px blue; */
}

.header-top-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-text-parent {
    margin-top: 15px;
    /* border: solid 3px red; */
}

.header-text {
    display: flex;
    align-items: flex-start;
    /* border: solid 3px aqua; */
}

.header-text img {
    display: flex;
    flex-direction: column;
    /* border: solid 3px red; */
}

.header-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-text);
}

.header-buttons {
    width: 100%;
    margin: 40px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* border: solid 3px aqua; */
}

#button-2 {
    margin-left: 30px;
}

.header-buttons button {
    flex: 0.5;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background: var(--button);
    border: 5px solid var(--button);
    padding: 0 1rem;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin-right: 25px;
}

.header-site-visit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: solid 3px red; */
}

.header-site-visit img {
    width: fit-content;
    height: fit-content;
}

.header-site-visit p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text);
    text-align: left;
    padding-left: 20px;
}

.header-big-pic {
    display: flex;
    justify-content: center;
    /* border: solid 3px red; */
    height: 100%;
    width: fit-content;
}

.header-big-pic img {
    margin: -60px 10px 0 0;
    width: 105%;
    height: 105%;
}

@media screen and (max-width: 1000px) {
    .header-mother {
        flex-direction: row;
        padding:70px 30px 20px 30px;
    }

    .header-top {
        display: flex;
        flex-direction: column;
        /* border: solid 3px grey; */
    }

    .header-top-content {
        width: 100%;
        /* border: solid 3px red; */
    }

    .header-top-content h1 {
        font-size: 44px;
        line-height: 60px;
        letter-spacing: -0.04em;
    }

    .header-site-visit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .header-site-visit p {
        padding: 0;
    }

    .header-big-pic {
        padding: 40px 0;
    }

    .header-big-pic img{
        display: flex;
        margin:0;
        width: 100%;
        height: 100%;
    }
}

