.deduction-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding: 25px 40px;
    /* border: solid 3px red */
}

.deduction-parent img {
    width: 60px;
    height: 60px;
}

.deduction-parent h1 {
    display: flex;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    font-family: var(--font-family);
    margin-top: 20px;
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    line-height: 24px;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* border: solid 3px red; */
    width: 275px;
}

.deduction-parent p {
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 18px;
    letter-spacing: +.01rem;
}

