.how-it-works-full {
    padding: 50px 50px 100px 50px;
    background-color:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%)
}

.how-it-works-full h2 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    width: 100%;
    font-weight: 400;
    letter-spacing: -0.01rem;
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-it-works-mother {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 50px;
}

.how-it-works-parent {
    display: flex;
    height: max-content;
    width: max-content;
    color: red;
}

@media screen and (max-width: 850px) {
    .how-it-works-full {
        display: flex;
        flex-direction: column;
     }

     .how-it-works-full h2 {
        padding: 40px 0;
        display: flex;
        color: white;
        /* border: solid 3px white; */
    }

     .how-it-works-mother {
         display: flex;
         flex-direction: column;
         padding-top: 25px;
     }

     .how-it-works-parent {
         display: flex;
         height: max-content;
         width: max-content;
         /* border: 3px solid white; */
     }

     .how-it-works-parent text {
         font-size: 25px;
     }

     .how-it-works-arrow img{
         /* border: solid 3px white;
         transform: rotate(90deg); */
         visibility: hidden;
     }
}

@media screen and (max-width: 650px) {
    .how-it-works-full {
        display: flex;
        flex-direction: column;
     }

     .how-it-works-mother {
         display: flex;
         flex-direction: column;
         padding-top: 25px;
     }

     .how-it-works-parent {
         display: flex;
         height: max-content;
         width: max-content;
         /* border: 3px solid white; */
     }

     .how-it-works-parent text {
         font-size: 25px;
     }

     .how-it-works-full h2 {
        padding: 40px 0;
         display: flex;
         color: white;
         /* border: solid 3px white; */
     }

     .how-it-works-arrow img{
         /* border: solid 3px white;
         transform: rotate(90deg); */
         visibility: hidden;
     }
}

@media screen and (max-width: 425px) {
    .how-it-works-full {
       display: flex;
       flex-direction: column;
       padding: 50px;
    }

    .how-it-works-mother {
        display: flex;
        flex-direction: column;
        padding-top: 25px;
    }

    .how-it-works-parent {
        display: flex;
        height: max-content;
        width: max-content;
        /* border: 3px solid white; */
    }

    .how-it-works-parent text {
        font-size: 25px;
    }

    .how-it-works-full h2 {
        padding: 40px 0;
        display: flex;
        color: white;
        /* border: solid 3px white; */
    }

    .how-it-works-arrow img{
        /* border: solid 3px white;
        transform: rotate(90deg); */
        visibility: hidden;
    }
}