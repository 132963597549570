@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 6rem;
}

.gpt3__navbar-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* border: solid green; */
}

.gpt3__navbar-links_logo {
    display: flex;
    margin-right: 2rem;
    align-items: center;
    justify-content: center;
    /* border: solid aqua; */
}

.gpt3__navbar-links_logo img {
    display: flex;
    width: 50px;
    height: 50px;
    /* border: solid red; */
}

.logo-final img {
    width: 60px;
    height: 60px;
    margin-left: 15px;
}

.gpt3__navbar-links_container {
    display: flex;
    justify-content: space-between;
    width: 70%;
    flex-direction: row;
    align-items: center;
    /* border: solid aqua; */
}
.gpt3__navbar-links_container p {
    font-size: 18px;
    color: var(--color-text);
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none; /* Hide dropdown menu by default */
    position: absolute;
    top: 100%; /* Position below the dropdown-toggle */
    left: -11px;
    width: 200px; /* Adjust width as needed */
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    /* border: solid red; */
}

.dropdown-menu p {
    margin: 0;
    padding: 10px;
    cursor: pointer;
}

.dropdown-menu p a {
    text-decoration: none;
    color: var(--color-text);
}

.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
    display: block;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu p:hover {
    background-color: #000061; /* Light background on hover */
}

/* Navbar Menu for mobile view */
.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 60px; /* Adjust based on navbar height */
    background-color: #012852;
    /* border: 1px solid #ddd; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 210px;
    border-radius: 5px;
    padding: 2rem;
}

.gpt3__navbar-menu_container-links {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: flex-end;
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
    color: var(--color-text);
    font-size: 20px;
}

.dropdown-mobile {
    position: relative;
}

.dropdown-menu-mobile {
    display: none; /* Hide dropdown menu by default */
    position: relative;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    /* border: solid red; */
}

.dropdown-mobile:hover .dropdown-menu-mobile,
.dropdown-menu-mobile:hover {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu-mobile p:hover {
    background-color: #000061; /* Light background on hover */
}

.dropdown-toggle-mobile, .dropdown-toggle-mobile:hover{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* border:solid red; */
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}

.header-mother {
    display: flex;
    flex-direction: column;
    /* border: solid 3px green; */
    padding: 160px 90px;
}

.header-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    /* border: solid 3px blue; */
}

.header-image {
    display: flex;
    /* border: solid 3px red; */
    justify-content: center;
}

.header-image img{
    display: flex;
    /* border: solid 3px red; */
    width: 90%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
    align-items: center;
}

.header-heading {
    /* border: solid 3px red; */
    padding: 100px 0 0 0;
}

.header-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    /* border: solid 3px green; */
}

.header-text {
    display: flex;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    padding-top: 1rem;
    /* border: solid 3px red; */
}

.header-text img {
    display: flex;
    /* border: solid 3px red; */
    margin-right: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: -5px;
}

.header-text p {
    display: flex;
    font-size: 10px;
    /* border: solid 3px red; */
    /* color: white; */
}

.header-button {
    display: flex;
    /* border: solid 3px red; */
    justify-content: space-around;
    align-self: center;
    padding: 2rem 0;
}

.header-button button{
    flex: 1 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    background: var(--button);
    border: 5px solid var(--button);
    padding: 0 1rem;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin-right: 25px;
}

.header-site-visit {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    /* align-items: center; */
}

.header-site-visit img {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px 0;
}

.header-site-visit p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-text);
    align-items: center;
    justify-content: center;
}
.homestudent-mother {
    display: flex;
    flex-direction: column;
    background-color: #ffe4d5;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 40px 0;
}

.homestudent-mother h1{
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    font-size: 46px;
}

.service-item {
    display: flex;
    flex-direction: column;
    /* border:solid 3px red; */
    align-items: center;
}

.service-items-container {
    display: flex;
    width: 100%;
    /* border:solid 3px green; */
}

@media screen and (max-width: 800px) {
    .service-item {
        padding: 40px 0;
    }

    .service-items-container {
        flex-direction: column;
    }
}
.services-mother {
    display: flex;
    padding: 20px;
    width: 90%;
    /* height: 500PX; */
    flex-direction: column;
    justify-content: center;
    /* border: solid 3px red; */
    background-color: #FFE4D5;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.68);
}

.services-image {
    display: flex;
    width: 85%;
    height: 85%;
    border-radius: 20px;
    overflow: hidden;
    /* border: solid 3px red; */
}

.services-content {
    margin: 10px;
    display: flex;
    flex-direction: column;
    /* border: solid 3px red; */
    align-items: center;
    width:350px;

}

.services-heading {
    padding: 15px;
    font-size: 26px;
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
}

.services-explanation {
    font-family: var(--font-family);
    font-weight: 400;
    width:80%;
    padding-left: 10px;
    /* border: solid aqua; */
    /* height: 300px; */
}

.services-button{
    display: flex;
    margin: 20px 0;
    width: 60%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    background: var(--button);
    border: 5px solid var(--button);
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    text-align: center;
    align-items: center;
    justify-content: center;
    /* border: solid 3px blue; */
}

@media screen and (min-width: 900px) {
    .services-explanation {
        height: 150px;
    }

    .services-image {
        width: 300px;
        height: 300px;
    }

    .services-explanation {
        width:90%;
    }
}


.how-it-works-full {
    padding: 50px 50px 100px 50px;
    background-color:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%)
}

.how-it-works-full h2 {
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    width: 100%;
    font-weight: 400;
    letter-spacing: -0.01rem;
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-it-works-mother {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 50px;
}

.how-it-works-parent {
    display: flex;
    height: -webkit-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: max-content;
    color: red;
}

@media screen and (max-width: 850px) {
    .how-it-works-full {
        display: flex;
        flex-direction: column;
     }

     .how-it-works-full h2 {
        padding: 40px 0;
        display: flex;
        color: white;
        /* border: solid 3px white; */
    }

     .how-it-works-mother {
         display: flex;
         flex-direction: column;
         padding-top: 25px;
     }

     .how-it-works-parent {
         display: flex;
         height: -webkit-max-content;
         height: max-content;
         width: -webkit-max-content;
         width: max-content;
         /* border: 3px solid white; */
     }

     .how-it-works-parent text {
         font-size: 25px;
     }

     .how-it-works-arrow img{
         /* border: solid 3px white;
         transform: rotate(90deg); */
         visibility: hidden;
     }
}

@media screen and (max-width: 650px) {
    .how-it-works-full {
        display: flex;
        flex-direction: column;
     }

     .how-it-works-mother {
         display: flex;
         flex-direction: column;
         padding-top: 25px;
     }

     .how-it-works-parent {
         display: flex;
         height: -webkit-max-content;
         height: max-content;
         width: -webkit-max-content;
         width: max-content;
         /* border: 3px solid white; */
     }

     .how-it-works-parent text {
         font-size: 25px;
     }

     .how-it-works-full h2 {
        padding: 40px 0;
         display: flex;
         color: white;
         /* border: solid 3px white; */
     }

     .how-it-works-arrow img{
         /* border: solid 3px white;
         transform: rotate(90deg); */
         visibility: hidden;
     }
}

@media screen and (max-width: 425px) {
    .how-it-works-full {
       display: flex;
       flex-direction: column;
       padding: 50px;
    }

    .how-it-works-mother {
        display: flex;
        flex-direction: column;
        padding-top: 25px;
    }

    .how-it-works-parent {
        display: flex;
        height: -webkit-max-content;
        height: max-content;
        width: -webkit-max-content;
        width: max-content;
        /* border: 3px solid white; */
    }

    .how-it-works-parent text {
        font-size: 25px;
    }

    .how-it-works-full h2 {
        padding: 40px 0;
        display: flex;
        color: white;
        /* border: solid 3px white; */
    }

    .how-it-works-arrow img{
        /* border: solid 3px white;
        transform: rotate(90deg); */
        visibility: hidden;
    }
}
.how-it-work-parent {
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
}

.how-it-work-parent img {
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: max-content;
}

.how-it-work-parent p {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-text);
    padding-top: 15px;
}

@media screen and (max-width: 850px) {
    .how-it-work-parent p {
        justify-content: center;
        align-items: center;
        padding: 25px 0px;
    }
}

@media screen and (max-width: 650px) {
    .how-it-work-parent p {
        justify-content: center;
        align-items: center;
        padding: 25px 0px;
    }
}

@media screen and (max-width: 425px) {
    .how-it-work-parent p {
        justify-content: center;
        align-items: center;
        padding: 25px 0px;
    }
}
.footer-full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
    padding: 75px;
}

.footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1.5rem;
    text-align: center;
    margin-bottom: 5rem;
    cursor: pointer;
    border-radius: 25px;
    background-color: red;
}

.footer-btn a {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    word-spacing: 2px;
    text-align: center;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.footer-links-logo {
    display: flex;
    flex-direction: column;
}

.footer-links-logo img {
    width: 60px;
    height: 60px;
    /* border: solid 3px white; */
    margin-left: 3rem;
}

.footer-links-logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #FFF;
}

.footer-links-div  {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer-links div {
    width: 250px;
}

.footer-links-div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: var(--color-text);
    margin-bottom: 0.9rem;
}

.footer-links-div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: var(--color-text);
    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-links-div a {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: var(--color-text);
    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: var(--color-text);
}

@media screen and (max-width: 850px) {
    .footer-full {
        padding: 75px 40px;
    }

    .footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }

    .footer-btn {
        margin-bottom: 2rem;
    }

    .footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-links-logo {
        display: none;
    }

    .footer-links div {
        margin: 1rem 0;
    }

    .footer-links div {
        /* border: solid 3px blue; */
        width: 50%
    }

    #links-2 {
        display: none;
    }

    #links-3 {
        text-align: end;
        padding-right: 15PX;
    }

}

@media screen and (max-width: 550px) {

    .footer-full {
        padding: 75px 40px;
    }

    .footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footer-btn {
        margin-bottom: 2rem;
    }

    .footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-links-logo {
        display: none;
    }

    .footer-links div {
        margin: 1rem 0;
    }

    .footer-links div {
        /* border: solid 3px blue; */
        width: 50%
    }

    #links-2 {
        display: none;
    }

    #links-3 {
        text-align: end;
        padding-right: 15px;
    }
}

@media screen and (max-width: 400px) {

    .footer-full {
        padding: 75px 40px;
    }

    .footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footer-btn {
        margin-bottom: 2rem;
    }

    .footer-links-logo {
        display: none;
    }

    .footer-links div {
        /* border: solid 3px blue; */
        width: 50%
    }

    #links-2 {
        display: none;
    }

    #links-3 {
        text-align: end;
        padding-right: 15PX;
    }
}

.student-deductions-parent {
    display: flex;
    flex-direction: column;
    background-color: #ffe4d5;
    justify-content: center;
    align-items: center;
    padding: 80px 30px;
}

.student-deductions-heading {
    text-align: center;
}

.student-deductions-heading h1{
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    font-size: 32px;
}

.student-deductions-heading p{
    font-family: var(--font-family);
    padding-top: 20px;
    font-weight: 400;
}

.deductions-all {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}

.deductions-all-2 {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}

.deductions-single {
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 850px) {
    .deductions-all {
        display: flex;
        flex-direction: column;
    }

    .deductions-all-2 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
}

@media screen and (max-width: 650px) {
    .deductions-all {
        display: flex;
        flex-direction: column;
    }

    .deductions-all-2 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
}

@media screen and (max-width: 425px) {
    .deductions-all {
        display: flex;
        flex-direction: column;
    }

    .deductions-all-2 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }

}
.deduction-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding: 25px 40px;
    /* border: solid 3px red */
}

.deduction-parent img {
    width: 60px;
    height: 60px;
}

.deduction-parent h1 {
    display: flex;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    font-family: var(--font-family);
    margin-top: 20px;
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    line-height: 24px;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* border: solid 3px red; */
    width: 275px;
}

.deduction-parent p {
    padding-top: 20px;
    text-align: center;
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 18px;
    letter-spacing: +.01rem;
}


.review_container{
    display: flex;
    flex-direction: column;
    border: 2px solid RGB(20, 20, 20);
    min-width: 250px;
    height: -webkit-max-content;
    height: max-content;
    color: white;
    border-radius: 10px;
    background-color: RGBA(20, 20, 20, 0.9);
    box-shadow: 0 0 15px rgba(0, 0, 0, 1.0);
    padding: 10px 5px;
}

.review_name{
    font-weight: 500;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: var(--color-text);
}

.review_test{
    font-weight: 300;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text);
}




@-webkit-keyframes slide {
    from{
        -webkit-transform: translateX(0);
                transform: translateX(0)
    }
    to{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%)
    }
}

@keyframes slide {
    from{
        -webkit-transform: translateX(0);
                transform: translateX(0)
    }
    to{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%)
    }
}

.reviews_container {
    display: flex;
    flex-direction: column;
    height: -webkit-max-content;
    height: max-content;
    align-items: center;
    overflow: hidden;
    background-color: #ffe4d5;
    padding: 50px 15px;
}

.arrows {
    display: flex;
    flex-direction: row;
    padding-top: 25px;
}

.arrows img {
    height: 40px;
    width: 40px;
}

.reviews_heading {
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    letter-spacing: -0.04rem;
}

.reviews-text {
    padding-top: 15px;
    text-align: center;
}

.reviews {
    display: flex;
    flex-direction: row;
    height: 250px;
    align-items: center;
    justify-content: center;
}

.review_single_container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    -webkit-animation: 50s slide infinite linear;
            animation: 50s slide infinite linear;
}

.review_single {
    margin: 25px;
    display: inline-block;
}

@media screen and (max-width: 850px) {
    .reviews_heading h1{
        letter-spacing: -0.06rem;
        text-align: center;
        line-height: 35px;
    }

    .reviews_heading p{
        letter-spacing: -0.02rem;
        text-align: center;
    }
}

@media screen and (max-width: 650px) {
    .reviews_heading h1{
        letter-spacing: -0.06rem;
        text-align: center;
        line-height: 35px;
    }

    .reviews_heading p{
        letter-spacing: -0.02rem;
        text-align: center;
    }
}

@media screen and (max-width: 425px) {
    .reviews_heading h1{
        letter-spacing: -0.06rem;
        text-align: center;
        line-height: 35px;
    }

    .reviews_heading p{
        letter-spacing: -0.02rem;
        text-align: center;
    }
}
.faq-component {
    align-items: center;
    overflow: hidden;
    transition: height 0.5s ease;
    /* border: solid green; */
    width: 100%;
    padding: 0 0 20px 0;
}

.arrow-container{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    /* border: solid red; */
}

.question {
    color: #BD71DB;
    padding: 0px 20px;
    /* border: solid blue; */
}

.question p{
    font-size: 20px;
}

.answer {
    color: var(--color-text);
    opacity: 1;
    transition: opacity 0.5s ease, -webkit-transform 0.5s ease;
    transition: opacity 0.5s ease, transform 0.5s ease;
    transition: opacity 0.5s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
    padding: 20px 0 40px 42px;
}

.answer p{
    font-size: 20px;
}

.arrow-icon {
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    /* border: solid aqua; Smooth transition on the transform property */
}

.rotate-up {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); /* Rotate the arrow upwards */
}

.answer-container {
    overflow: hidden;
}

.faq-component.expanded .answer {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease; /* Optional: Add a transition effect for content */
}

.faq-container {
    display: flex;
    flex-direction: row;
    padding: 100px 25px 100px 10px;
    background-color:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%)
}

.faq-container-left {
    width: 45%;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 0 120px;
}

.faq-container-left h1{
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.02rem;
    text-align: right;
}

.faq-container-right {
    width: 50%;
    color: white;
    /* border: solid 3px white; */
}

@media screen and (max-width: 850px) {
    .faq-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faq-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faq-container-left h1{
        text-align: center;
    }

    .faq-container-right {
        padding: 50px 20px;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .faq-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faq-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faq-container-left h1{
        text-align: center;
    }

    .faq-container-right {
        padding: 50px 20px;
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .faq-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faq-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faq-container-left h1{
        text-align: center;
    }

    .faq-container-right {
        padding: 50px 20px;
        width: 100%;
    }
}
.cta-button img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 45px;
}
.cta-full {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 30px;
    right: 35px;
    height: 150px;
    align-items: right;
    justify-content: space-between;
    z-index: 100;
    /* border: solid 3px red; */
}

.cta-icons {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: flex-end;
    align-items: center;
}

.cta-icons p {
    margin-right: 15px;
    color: white;
    font-weight: 300;
    background-color: #45E15F;
    max-width: 80%;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
}

@media screen and (max-width: 850px) {
    .cta-full {
        bottom: 20px;
        right: 20px;
    }
}

@media screen and (max-width: 650px) {
    .cta-full {
        bottom: 20px;
        right: 20px;
    }
}

@media screen and (max-width: 400px) {
    .cta-full {
        bottom: 20px;
        right: 20px;
    }
}
.header-mother {
    display: flex;
    flex-direction: column;
    /* border: solid 3px green; */
    padding: 130px 90px;
}

.header-top {
    display: flex;
    /* border: solid 3px blue; */
    justify-content: center;
}

.header-top-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    /* border: solid 3px blue; */
}

.header-top-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-text-parent {
    margin-top: 15px;
    /* border: solid 3px red; */
}

.header-text {
    display: flex;
    align-items: flex-start;
    /* border: solid 3px aqua; */
}

.header-text img {
    display: flex;
    flex-direction: column;
    /* border: solid 3px red; */
}

.header-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-text);
}

.header-buttons {
    width: 100%;
    margin: 40px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* border: solid 3px aqua; */
}

#button-2 {
    margin-left: 30px;
}

.header-buttons button {
    flex: 0.5 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background: var(--button);
    border: 5px solid var(--button);
    padding: 0 1rem;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin-right: 25px;
}

.header-site-visit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: solid 3px red; */
}

.header-site-visit img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.header-site-visit p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text);
    text-align: left;
    padding-left: 20px;
}

.header-big-pic {
    display: flex;
    justify-content: center;
    /* border: solid 3px red; */
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.header-big-pic img {
    margin: -60px 10px 0 0;
    width: 105%;
    height: 105%;
}

@media screen and (max-width: 1000px) {
    .header-mother {
        flex-direction: row;
        padding:70px 30px 20px 30px;
    }

    .header-top {
        display: flex;
        flex-direction: column;
        /* border: solid 3px grey; */
    }

    .header-top-content {
        width: 100%;
        /* border: solid 3px red; */
    }

    .header-top-content h1 {
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.04em;
    }

    .header-site-visit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .header-site-visit p {
        padding: 0;
    }

    .header-big-pic {
        padding: 40px 0;
    }

    .header-big-pic img{
        display: flex;
        margin:0;
        width: 100%;
        height: 100%;
    }
}


.header-mother {
    display: flex;
    flex-direction: column;
    /* border: solid 3px green; */
    padding: 130px 90px;
}

.header-top {
    display: flex;
    /* border: solid 3px blue; */
    justify-content: center;
}

.header-top-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    /* border: solid 3px blue; */
}

.header-top-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-text-parent {
    margin-top: 15px;
    /* border: solid 3px red; */
}

.header-text {
    display: flex;
    align-items: flex-start;
    /* border: solid 3px aqua; */
}

.header-text img {
    display: flex;
    flex-direction: column;
    /* border: solid 3px red; */
}

.header-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-text);
}

.header-buttons {
    width: 100%;
    margin: 40px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* border: solid 3px aqua; */
}

#button-2 {
    margin-left: 30px;
}

.header-buttons button {
    flex: 0.5 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background: var(--button);
    border: 5px solid var(--button);
    padding: 0 1rem;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin-right: 25px;
}

.header-site-visit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: solid 3px red; */
}

.header-site-visit img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.header-site-visit p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text);
    text-align: left;
    padding-left: 20px;
}

.header-big-pic {
    display: flex;
    justify-content: center;
    /* border: solid 3px red; */
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.header-big-pic img {
    margin: -60px 10px 0 0;
    width: 105%;
    height: 105%;
}

@media screen and (max-width: 1000px) {
    .header-mother {
        flex-direction: row;
        padding:70px 30px 20px 30px;
    }

    .header-top {
        display: flex;
        flex-direction: column;
        /* border: solid 3px grey; */
    }

    .header-top-content {
        width: 100%;
        /* border: solid 3px red; */
    }

    .header-top-content h1 {
        font-size: 44px;
        line-height: 60px;
        letter-spacing: -0.04em;
    }

    .header-site-visit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .header-site-visit p {
        padding: 0;
    }

    .header-big-pic {
        padding: 40px 0;
    }

    .header-big-pic img{
        display: flex;
        margin:0;
        width: 100%;
        height: 100%;
    }
}


.personal-deductions-parent {
    display: flex;
    flex-direction: column;
    background-color: #ffe4d5;
    justify-content: center;
    align-items: center;
    padding: 80px 30px;
}

.personal-deductions-heading {
    text-align: center;
}

.personal-deductions-heading h1{
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    font-size: 32px;
}

.personal-deductions-heading p{
    font-family: var(--font-family);
    padding-top: 20px;
    font-weight: 400;
}

.deductions-all {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}

.deductions-all-2, .deductions-all-3 {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}

.deductions-single {
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 850px) {
    .deductions-all {
        display: flex;
        flex-direction: column;
    }

    .deductions-all-2, .deductions-all-3 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
}

@media screen and (max-width: 650px) {
    .deductions-all {
        display: flex;
        flex-direction: column;
    }

    .deductions-all-2, .deductions-all-3 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
}

@media screen and (max-width: 425px) {
    .deductions-all {
        display: flex;
        flex-direction: column;
    }

    .deductions-all-2, .deductions-all-3 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }

}
.header-mother {
    display: flex;
    flex-direction: column;
    /* border: solid 3px green; */
    padding: 100px 90px;
}

.header-top {
    display: flex;
    /* border: solid 3px blue; */
    justify-content: center;
}

.header-top-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    /* border: solid 3px blue; */
}

.header-top-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-text-parent {
    margin-top: 15px;
    /* border: solid 3px red; */
}

.header-text {
    display: flex;
    align-items: flex-start;
    /* border: solid 3px aqua; */
}

.header-text img {
    display: flex;
    flex-direction: column;
    /* border: solid 3px red; */
}

.header-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--color-text);
}

.header-buttons {
    width: 100%;
    margin: 40px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* border: solid 3px aqua; */
}

#button-2 {
    margin-left: 30px;
}

.header-buttons button {
    flex: 0.5 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    background: var(--button);
    border: 5px solid var(--button);
    padding: 0 1rem;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin-right: 25px;
}

.header-site-visit {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: solid 3px red; */
}

.header-site-visit img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.header-site-visit p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text);
    text-align: left;
    padding-left: 20px;
}

.header-big-pic {
    display: flex;
    justify-content: center;
    /* border: solid 3px red; */
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.header-big-pic img {
    margin: -60px 10px 0 0;
    width: 105%;
    height: 105%;
}

@media screen and (max-width: 1000px) {
    .header-mother {
        flex-direction: row;
        padding:70px 30px 20px 30px;
    }

    .header-top {
        display: flex;
        flex-direction: column;
        /* border: solid 3px grey; */
    }

    .header-top-content {
        width: 100%;
        /* border: solid 3px red; */
    }

    .header-top-content h1 {
        font-size: 44px;
        line-height: 60px;
        letter-spacing: -0.04em;
    }

    .header-site-visit {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .header-site-visit p {
        padding: 0;
    }

    .header-big-pic {
        padding: 40px 0;
    }

    .header-big-pic img{
        display: flex;
        margin:0;
        width: 100%;
        height: 100%;
    }
}


@-webkit-keyframes slide {
    from{
        -webkit-transform: translateX(0);
                transform: translateX(0)
    }
    to{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%)
    }
}

@keyframes slide {
    from{
        -webkit-transform: translateX(0);
                transform: translateX(0)
    }
    to{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%)
    }
}

.logos {
    overflow: hidden;
    white-space: nowrap;
}

.logos-slide{
    display: inline-block;
    /* justify-content: center; */
    -webkit-animation: 5s slide infinite linear;
            animation: 5s slide infinite linear;
}

.logos-slide img{
    margin: 0 25px;
}



.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.gpt3__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.gpt3__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.gpt3__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.gpt3__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.gpt3__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
    }

    .gpt3__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 6rem;
}

.gpt3__navbar-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* border: solid green; */
}

.gpt3__navbar-links_logo {
    display: flex;
    margin-right: 2rem;
    align-items: center;
    justify-content: center;
    /* border: solid aqua; */
}

.gpt3__navbar-links_logo img {
    display: flex;
    width: 50px;
    height: 50px;
    /* border: solid red; */
}

.logo-final img {
    width: 60px;
    height: 60px;
    margin-left: 15px;
}

.gpt3__navbar-links_container {
    display: flex;
    justify-content: space-between;
    width: 70%;
    flex-direction: row;
    align-items: center;
    /* border: solid aqua; */
}
.gpt3__navbar-links_container p {
    font-size: 18px;
    color: var(--color-text);
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none; /* Hide dropdown menu by default */
    position: absolute;
    top: 100%; /* Position below the dropdown-toggle */
    left: -11px;
    width: 200px; /* Adjust width as needed */
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    /* border: solid red; */
}

.dropdown-menu p {
    margin: 0;
    padding: 10px;
    cursor: pointer;
}

.dropdown-menu p a {
    text-decoration: none;
    color: var(--color-text);
}

.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
    display: block;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu p:hover {
    background-color: #000061; /* Light background on hover */
}

/* Navbar Menu for mobile view */
.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 60px; /* Adjust based on navbar height */
    background-color: #012852;
    /* border: 1px solid #ddd; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 210px;
    border-radius: 5px;
    padding: 2rem;
}

.gpt3__navbar-menu_container-links {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: flex-end;
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
    color: var(--color-text);
    font-size: 20px;
}

.dropdown-mobile {
    position: relative;
}

.dropdown-menu-mobile {
    display: none; /* Hide dropdown menu by default */
    position: relative;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    /* border: solid red; */
}

.dropdown-mobile:hover .dropdown-menu-mobile,
.dropdown-menu-mobile:hover {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu-mobile p:hover {
    background-color: #000061; /* Light background on hover */
}

.dropdown-toggle-mobile, .dropdown-toggle-mobile:hover{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* border:solid red; */
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}

.faqstud-container {
    display: flex;
    flex-direction: row;
    padding: 100px 25px 100px 10px;
    /* background-color:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%) */
}

.faqstud-container-left {
    width: 45%;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 0 120px;
}

.faqstud-container-left h1{
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.02rem;
    text-align: right;
}

.faqstud-container-right {
    width: 50%;
    color: white;
    /* border: solid 3px white; */
}

@media screen and (max-width: 850px) {
    .faqstud-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqstud-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqstud-container-left h1{
        text-align: center;
    }

    .faqstud-container-right {
        padding: 50px 20px;
        width: 90%;

    }
}

@media screen and (max-width: 650px) {
    .faqstud-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqstud-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqstud-container-left h1{
        text-align: center;
    }

    .faqstud-container-right {
        padding: 50px 20px;
        width: 90%;
        /* border: solid red; */
    }
}

@media screen and (max-width: 400px) {
    .faqstud-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqstud-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqstud-container-left h1{
        text-align: center;
    }

    .faqstud-container-right {
        padding: 50px 20px;
        width: 90%;
    }
}
.faqpers-container {
    display: flex;
    flex-direction: row;
    padding: 100px 25px 100px 10px;
    /* background-color:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%) */
}

.faqpers-container-left {
    width: 45%;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 0 120px;
}

.faqpers-container-left h1{
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.02rem;
    text-align: right;
}

.faqpers-container-right {
    width: 50%;
    color: white;
    /* border: solid 3px white; */
}

@media screen and (max-width: 850px) {
    .faqpers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqpers-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqpers-container-left h1{
        text-align: center;
    }

    .faqpers-container-right {
        padding: 50px 20px;
        width: 90%;

    }
}

@media screen and (max-width: 650px) {
    .faqpers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqpers-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqpers-container-left h1{
        text-align: center;
    }

    .faqpers-container-right {
        padding: 50px 20px;
        width: 90%;
        /* border: solid red; */
    }
}

@media screen and (max-width: 400px) {
    .faqpers-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqpers-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqpers-container-left h1{
        text-align: center;
    }

    .faqpers-container-right {
        padding: 50px 20px;
        width: 90%;
    }
}
.coorporate-services-parent {
    display: flex;
    flex-direction: column;
    background-color: #ffe4d5;
    justify-content: center;
    align-items: center;
    padding: 80px 30px;
}

.coorporate-services-heading {
    text-align: center;
}

.coorporate-services-heading h1{
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    font-size: 32px;
}

.coorporate-services-heading p{
    font-family: var(--font-family);
    padding-top: 20px;
    font-weight: 400;
}

.services-all {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}

.services-all-2 {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
}

.services-single {
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 850px) {
    .services-all {
        display: flex;
        flex-direction: column;
    }

    .services-all-2 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
}

@media screen and (max-width: 650px) {
    .services-all {
        display: flex;
        flex-direction: column;
    }

    .services-all-2 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
}

@media screen and (max-width: 425px) {
    .services-all {
        display: flex;
        flex-direction: column;
    }

    .services-all-2 {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }

}
.help-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding: 25px 40px;
    /* border: solid 3px red; */
}

.help-parent img {
    width: 80px;
    height: 80px;
    /* border: solid 3px red; */
}

.help-parent h1 {
    display: flex;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    line-height: 24px;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* border: solid 3px red; */
    width: 275px;
    /* border: solid red; */
}

.help-parent p {
    text-align: center;
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 20px;
    letter-spacing: +.025rem;
    /* border: solid green; */
}


.faqcorp-container {
    display: flex;
    flex-direction: row;
    padding: 100px 25px 100px 10px;
    /* background-color:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%) */
}

.faqcorp-container-left {
    width: 45%;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 0 120px;
}

.faqcorp-container-left h1{
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.02rem;
    text-align: right;
}

.faqcorp-container-right {
    width: 50%;
    color: white;
    /* border: solid 3px white; */
}

@media screen and (max-width: 850px) {
    .faqcorp-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqcorp-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqcorp-container-left h1{
        text-align: center;
    }

    .faqcorp-container-right {
        padding: 50px 20px;
        width: 90%;

    }
}

@media screen and (max-width: 650px) {
    .faqcorp-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqcorp-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqcorp-container-left h1{
        text-align: center;
    }

    .faqcorp-container-right {
        padding: 50px 20px;
        width: 90%;
        /* border: solid red; */
    }
}

@media screen and (max-width: 400px) {
    .faqcorp-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqcorp-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqcorp-container-left h1{
        text-align: center;
    }

    .faqcorp-container-right {
        padding: 50px 20px;
        width: 90%;
    }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }
}
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  --button: red;

  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
  --color-text-gradient:linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(/static/media/Gilroy-Bold.ca49f765.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(/static/media/Gilroy-Medium.1b4da658.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(/static/media/Gilroy-Light.1822a291.ttf) format('truetype');
}

