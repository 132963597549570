.help-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding: 25px 40px;
    /* border: solid 3px red; */
}

.help-parent img {
    width: 80px;
    height: 80px;
    /* border: solid 3px red; */
}

.help-parent h1 {
    display: flex;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    font-family: var(--font-family);
    color: RGB(47, 46, 46);
    letter-spacing: -0.04rem;
    line-height: 24px;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* border: solid 3px red; */
    width: 275px;
    /* border: solid red; */
}

.help-parent p {
    text-align: center;
    font-size: 16px;
    font-family: var(--font-family);
    line-height: 20px;
    letter-spacing: +.025rem;
    /* border: solid green; */
}

