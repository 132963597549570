.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 6rem;
}

.gpt3__navbar-links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* border: solid green; */
}

.gpt3__navbar-links_logo {
    display: flex;
    margin-right: 2rem;
    align-items: center;
    justify-content: center;
    /* border: solid aqua; */
}

.gpt3__navbar-links_logo img {
    display: flex;
    width: 50px;
    height: 50px;
    /* border: solid red; */
}

.logo-final img {
    width: 60px;
    height: 60px;
    margin-left: 15px;
}

.gpt3__navbar-links_container {
    display: flex;
    justify-content: space-between;
    width: 70%;
    flex-direction: row;
    align-items: center;
    /* border: solid aqua; */
}
.gpt3__navbar-links_container p {
    font-size: 18px;
    color: var(--color-text);
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none; /* Hide dropdown menu by default */
    position: absolute;
    top: 100%; /* Position below the dropdown-toggle */
    left: -11px;
    width: 200px; /* Adjust width as needed */
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    /* border: solid red; */
}

.dropdown-menu p {
    margin: 0;
    padding: 10px;
    cursor: pointer;
}

.dropdown-menu p a {
    text-decoration: none;
    color: var(--color-text);
}

.dropdown:hover .dropdown-menu,
.dropdown-menu:hover {
    display: block;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu p:hover {
    background-color: #000061; /* Light background on hover */
}

/* Navbar Menu for mobile view */
.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 60px; /* Adjust based on navbar height */
    background-color: #012852;
    /* border: 1px solid #ddd; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 210px;
    border-radius: 5px;
    padding: 2rem;
}

.gpt3__navbar-menu_container-links {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: flex-end;
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
    color: var(--color-text);
    font-size: 20px;
}

.dropdown-mobile {
    position: relative;
}

.dropdown-menu-mobile {
    display: none; /* Hide dropdown menu by default */
    position: relative;
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    /* border: solid red; */
}

.dropdown-mobile:hover .dropdown-menu-mobile,
.dropdown-menu-mobile:hover {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    opacity: 1;
    visibility: visible;
}

.dropdown-menu-mobile p:hover {
    background-color: #000061; /* Light background on hover */
}

.dropdown-toggle-mobile, .dropdown-toggle-mobile:hover{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* border:solid red; */
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}
