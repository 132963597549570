.faqstud-container {
    display: flex;
    flex-direction: row;
    padding: 100px 25px 100px 10px;
    /* background-color:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%) */
}

.faqstud-container-left {
    width: 45%;
    color: white;
    align-items: center;
    justify-content: center;
    padding: 0 120px;
}

.faqstud-container-left h1{
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.02rem;
    text-align: right;
}

.faqstud-container-right {
    width: 50%;
    color: white;
    /* border: solid 3px white; */
}

@media screen and (max-width: 850px) {
    .faqstud-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqstud-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqstud-container-left h1{
        text-align: center;
    }

    .faqstud-container-right {
        padding: 50px 20px;
        width: 90%;

    }
}

@media screen and (max-width: 650px) {
    .faqstud-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqstud-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqstud-container-left h1{
        text-align: center;
    }

    .faqstud-container-right {
        padding: 50px 20px;
        width: 90%;
        /* border: solid red; */
    }
}

@media screen and (max-width: 400px) {
    .faqstud-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .faqstud-container-left {
        width: 90%;
        padding: 0 10px;
    }

    .faqstud-container-left h1{
        text-align: center;
    }

    .faqstud-container-right {
        padding: 50px 20px;
        width: 90%;
    }
}