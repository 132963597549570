.header-mother {
    display: flex;
    flex-direction: column;
    /* border: solid 3px green; */
    padding: 160px 90px;
}

.header-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    /* border: solid 3px blue; */
}

.header-image {
    display: flex;
    /* border: solid 3px red; */
    justify-content: center;
}

.header-image img{
    display: flex;
    /* border: solid 3px red; */
    width: 90%;
    height: fit-content;
    justify-content: center;
    align-items: center;
}

.header-heading {
    /* border: solid 3px red; */
    padding: 100px 0 0 0;
}

.header-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.header-content {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    /* border: solid 3px green; */
}

.header-text {
    display: flex;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    padding-top: 1rem;
    /* border: solid 3px red; */
}

.header-text img {
    display: flex;
    /* border: solid 3px red; */
    margin-right: 20px;
    height: fit-content;
    margin-top: -5px;
}

.header-text p {
    display: flex;
    font-size: 10px;
    /* border: solid 3px red; */
    /* color: white; */
}

.header-button {
    display: flex;
    /* border: solid 3px red; */
    justify-content: space-around;
    align-self: center;
    padding: 2rem 0;
}

.header-button button{
    flex: 1;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    background: var(--button);
    border: 5px solid var(--button);
    padding: 0 1rem;
    color: white;
    cursor: pointer;
    outline: none;
    border-radius: 25px;
    margin-right: 25px;
}

.header-site-visit {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    /* align-items: center; */
}

.header-site-visit img {
    height: fit-content;
    width: fit-content;
    padding: 10px 0;
}

.header-site-visit p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-text);
    align-items: center;
    justify-content: center;
}