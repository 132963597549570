.review_container{
    display: flex;
    flex-direction: column;
    border: 2px solid RGB(20, 20, 20);
    min-width: 250px;
    height: max-content;
    color: white;
    border-radius: 10px;
    background-color: RGBA(20, 20, 20, 0.9);
    box-shadow: 0 0 15px rgba(0, 0, 0, 1.0);
    padding: 10px 5px;
}

.review_name{
    font-weight: 500;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: var(--color-text);
}

.review_test{
    font-weight: 300;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text);
}



