.footer-full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
    padding: 75px;
}

.footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1.5rem;
    text-align: center;
    margin-bottom: 5rem;
    cursor: pointer;
    border-radius: 25px;
    background-color: red;
}

.footer-btn a {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 24px;
    font-weight: 600;
    color: #fff;
    word-spacing: 2px;
    text-align: center;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.footer-links-logo {
    display: flex;
    flex-direction: column;
}

.footer-links-logo img {
    width: 60px;
    height: 60px;
    /* border: solid 3px white; */
    margin-left: 3rem;
}

.footer-links-logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #FFF;
}

.footer-links-div  {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.footer-links div {
    width: 250px;
}

.footer-links-div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: var(--color-text);
    margin-bottom: 0.9rem;
}

.footer-links-div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: var(--color-text);
    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-links-div a {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: var(--color-text);
    margin: 0.5rem 0;
    cursor: pointer;
}

.footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: var(--color-text);
}

@media screen and (max-width: 850px) {
    .footer-full {
        padding: 75px 40px;
    }

    .footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }

    .footer-btn {
        margin-bottom: 2rem;
    }

    .footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-links-logo {
        display: none;
    }

    .footer-links div {
        margin: 1rem 0;
    }

    .footer-links div {
        /* border: solid 3px blue; */
        width: 50%
    }

    #links-2 {
        display: none;
    }

    #links-3 {
        text-align: end;
        padding-right: 15PX;
    }

}

@media screen and (max-width: 550px) {

    .footer-full {
        padding: 75px 40px;
    }

    .footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footer-btn {
        margin-bottom: 2rem;
    }

    .footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-links-logo {
        display: none;
    }

    .footer-links div {
        margin: 1rem 0;
    }

    .footer-links div {
        /* border: solid 3px blue; */
        width: 50%
    }

    #links-2 {
        display: none;
    }

    #links-3 {
        text-align: end;
        padding-right: 15px;
    }
}

@media screen and (max-width: 400px) {

    .footer-full {
        padding: 75px 40px;
    }

    .footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footer-btn {
        margin-bottom: 2rem;
    }

    .footer-links-logo {
        display: none;
    }

    .footer-links div {
        /* border: solid 3px blue; */
        width: 50%
    }

    #links-2 {
        display: none;
    }

    #links-3 {
        text-align: end;
        padding-right: 15PX;
    }
}
