.faq-component {
    align-items: center;
    overflow: hidden;
    transition: height 0.5s ease;
    /* border: solid green; */
    width: 100%;
    padding: 0 0 20px 0;
}

.arrow-container{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    /* border: solid red; */
}

.question {
    color: #BD71DB;
    padding: 0px 20px;
    /* border: solid blue; */
}

.question p{
    font-size: 20px;
}

.answer {
    color: var(--color-text);
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
    padding: 20px 0 40px 42px;
}

.answer p{
    font-size: 20px;
}

.arrow-icon {
    transition: transform .5s ease;
    /* border: solid aqua; Smooth transition on the transform property */
}

.rotate-up {
    transform: rotate(-90deg); /* Rotate the arrow upwards */
}

.answer-container {
    overflow: hidden;
}

.faq-component.expanded .answer {
    opacity: 1;
    transform: translateY(0);
    transition: transform .5s ease; /* Optional: Add a transition effect for content */
}
